import { API_URL } from "../Constants/APIURL";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    try {
      const response = await fetch(`${API_URL}/refreshToken`, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      console.log("useRefreshToken", data);
      if (!response.ok) {
        console.log("error at useRefreshTOken");
        throw new Error(`HTTP error: ${response.status}`);
      }

      setAuth((prev) => {
        console.log(JSON.stringify(prev));
        console.log(data.accessToken);
        return {
          ...prev,
          role: data.role,
          accessToken: data.accessToken,
        };
      });
      return data.accessToken;
    } catch (error) {
      console.error("Error during refresh", error.message);
    }
  };
  return refresh;
};

export default useRefreshToken;
