import React, { useState, useEffect } from "react";
import "../../App.css";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Title from "../../Components/Title";
import { API_URL } from "../../Constants/APIURL";
import Table from "../../Components/Table";
import { useNavigate } from "react-router-dom";
import Alert from "../../Components/Alert";
import AWS from "aws-sdk";
import SideMenu from "../../Components/SideMenu";
// Configure the AWS SDK with your credentials
AWS.config.update({
  accessKeyId: "AKIAU7JWGTGRLUIUZCH6",
  secretAccessKey: "6FuhIojnaBGmwGFiZZ7GIenaphsX0K77ZuELQKyq",
  region: "ap-southeast-1", // Replace with your desired AWS region, e.g., 'us-east-1'
});

const ManageAdmin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [adminData, setAdminData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    loadAdmin();
  }, []);

  const loadAdmin = () => {
    fetch(`${API_URL}/getAdminList`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          setIsError(true);
          setMessage(`HTTP error: ${response.status}`);
        }
        return response.json();
      })
      .then((adminData) => {
        setAdminData(adminData.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching adminusers", error);
      });
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.adminID,
      sortable: true,
      width: "80px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "200px",
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
      width: "200px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "300px",
    },
    {
      name: "Role",
      selector: (row) => {
        if (row.role) {
          return row.role;
        }
        return "N/A";
      },
      sortable: true,
      width: "300px",
    },
  ];

  if (loading) {
    return (
      <div className="content-wrapper">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        backgroundColor: "#f4f6f9",
      }}
    >
      <SideMenu />
      {/* Main content */}
      <div className="main-container">
        <Title title={"Manage Admin"}></Title>
        {message ? <Alert isError={isError} message={message} /> : null}
        <section className="content">
          <div className="container-fluid">
            <Table
              columns={columns}
              data={adminData}
              searchableAttribute={["email", "phone"]}
            ></Table>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ManageAdmin;
