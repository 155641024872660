import "../App.css";
import Footer from "../Components/Footer";
import Title from "../Components/Title";
import Alert from "../Components/Alert";
import SideMenu from "../Components/SideMenu";

const ViewOrder = () => {
  return (
    <div style={({ height: "100vh" }, { display: "flex" })}>
      <SideMenu />

      {/* Main content */}
      <div className="content-wrapper">
        <Title title={"View Order"}></Title>
        <section className="content">
          <div className="container-fluid">
            <div class="card">
              <div class="card-body">
                <div className="row align-items-center ">
                  <div className="col">
                    <i className="fas fa-solid fa-hashtag mr-2 " />
                    ORDER NUMBER: 284924 {/*orderID*/}
                    <h6 class="badge badge-secondary">
                      Daily Coffee Sentral Jaya
                    </h6>
                  </div>
                  <div className="col text-right">
                    <span className="text-success font-weight-bold">
                      Completed
                    </span>{" "}
                    {/*status*/}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {/* <!-- left column --> */}
              <div className="col-9">
                {/* <!--  Payment Information --> */}
                <div className="row">
                  <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                      <div className="card-header py-3">
                        <h5 className="m-0 font-weight-bold text-primary">
                          Payment Information
                        </h5>
                      </div>

                      {/* <!-- Card Body --> */}
                      <div className="card-body">
                        <div className="row mb-4">
                          {" "}
                          {/*Product order*/}
                          <div className="col-2 ">
                            <img src="../Assets/img/Cappuccino.png" />
                          </div>
                          <div className="col-5">
                            Cappucino <br /> Regular, Cold, Normal Sugar
                          </div>
                          <div className="col-2">RM 14.90</div>
                          <div className="col-1">X2</div>
                          <div className="col-2">29.80</div>
                        </div>
                        <div className="row ">
                          {" "}
                          {/*Product order*/}
                          <div className="col-2">
                            <img
                              src="./Assets/img/Capuccino.png"
                              className="img-fluid"
                            ></img>
                          </div>
                          <div className="col-5">
                            Cappucino <br /> Regular, Cold, Normal Sugar
                          </div>
                          <div className="col-2">RM 14.90</div>
                          <div className="col-1">X2</div>
                          <div className="col-2">29.80</div>
                        </div>
                        <hr></hr>
                        <div className="row">
                          <div className="col">Subtotal</div>
                          <div className="col text-right">RM 20.00</div>
                        </div>
                        <div className="row">
                          <div className="col">6% SST</div>
                          <div className="col text-right">RM 20.00</div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <span className="font-weight-bold">Total</span>
                          </div>
                          <div className="col text-right">RM 20.00</div>
                        </div>
                        <hr></hr>
                        <div className="row">
                          <div className="col">Daily Coffee Rewards Earned</div>
                          <div className="col text-right">20 pts</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="card">
                  <div className="card-header py-3">
                    <h5 className="m-0 font-weight-bold text-secondary">
                      {" "}
                      Customer
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="row align-items-center">
                      <i className="fas fa-solid fa-user"></i>
                      <span className="ml-3">Kokuhiro</span>
                      <span className="badge badge-gold ml-2">DC Gold</span>
                    </div>
                    <hr></hr>
                    <div className="row">
                      <span className="font-weight-bold">
                        Contact Information
                      </span>
                      <br />
                      <span>011-12345678</span>
                      <span className="overflow">kokuhiro@nbrand.com.my</span>
                    </div>
                    <hr></hr>
                    <span>Ordered on May 26, 2022 at 2:10 pm</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
      <div />
    </div>
  );
};
export default ViewOrder;
