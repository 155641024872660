import Header from "../Components/Header";
import Dashboard from "../Components/Dashboard";
import Footer from "../Components/Footer";

import SideMenu from "../Components/SideMenu";

function Home() {
  return (
    <div style={({ height: "100vh" }, { display: "flex" })}>
      <SideMenu />
      <main className="content" style={{ flex: "100%" }}>
        <div style={{ backgroundColor: "black" }}>header</div>
      </main>
    </div>
  );
}

export default Home;
