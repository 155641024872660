import React, { useState, useEffect } from "react";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import Title from "../../Components/Title";
import { API_URL } from "../../Constants/APIURL";
import Table from "../../Components/Table";
import Alert from "../../Components/Alert";
import AWS from "aws-sdk";
import SideMenu from "../../Components/SideMenu";
// Configure the AWS SDK with your credentials
AWS.config.update({
  accessKeyId: "AKIAU7JWGTGRLUIUZCH6",
  secretAccessKey: "6FuhIojnaBGmwGFiZZ7GIenaphsX0K77ZuELQKyq",
  region: "ap-southeast-1", // Replace with your desired AWS region, e.g., 'us-east-1'
});

const ManageFooter = () => {
  const [footerBanner, setFooterBanner] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    loadBanner();
  }, []);

  const loadBanner = () => {
    fetch(`${API_URL}/getFooterBanner`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          setIsError(true);
          setMessage(`HTTP error: ${response.status}`);
        }
        return response.json();
      })
      .then((bannerData) => {
        setFooterBanner(bannerData);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching footer banner", error);
        setLoading(false);
      });
  };

  const handleEditBanner = (bannerID) => {
    navigate(`/editFooterBanner/${bannerID}`);
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.bannerID,
      sortable: true,
      width: "80px",
    },
    {
      name: "Banner Type",
      selector: (row) => row.bannerType,
      sortable: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "Banner Title",
      selector: (row) => row.bannerTitle,
      sortable: true,
      width: "150px",
      wrap: true,
      style: {},
    },
    {
      name: "Start Date",
      selector: (row) => row.startDate,
      sortable: true,
      width: "100px",
      wrap: true,
      style: {},
    },
    {
      name: "End Date",
      selector: (row) => row.endDate,
      sortable: true,
      width: "100px",
      wrap: true,
      style: {},
    },
    {
      name: "Banner Image",
      cell: (row) => (
        <img
          src={row.bannerImage}
          style={{ width: "200px", height: "150px" }}
        />
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            style={{ marginRight: "10px" }}
            onClick={(e) => handleEditBanner(row.bannerID)}
          >
            Edit
          </button>
        </>
      ),
    },
  ];

  if (loading) {
    return (
      <div className="content-wrapper">
        <p>Loading...</p>
      </div>
    );
  }
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        backgroundColor: "#f4f6f9",
      }}
    >
      <SideMenu />
      {/* Main Content */}
      <div className="main-container">
        <Title title={"Manage Footer"}></Title>
        {message ? <Alert isError={isError} message={message} /> : null}
        <section className="content">
          <div className="container-fluid">
            <Table columns={columns} data={footerBanner}></Table>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ManageFooter;
