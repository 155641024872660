import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

import SideMenu from "../Components/SideMenu";
import AWS from "aws-sdk";
//import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox } from '@mui/material';
import { API_URL } from "../Constants/APIURL";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { GoogleMap, LoadScript, HeatmapLayer, HeatmapLayerF } from '@react-google-maps/api';

// Configure the AWS SDK with your credentials
AWS.config.update({
  accessKeyId: "AKIAU7JWGTGRLUIUZCH6",
  secretAccessKey: "6FuhIojnaBGmwGFiZZ7GIenaphsX0K77ZuELQKyq",
  region: "ap-southeast-1", // Replace with your desired AWS region, e.g., 'us-east-1'
});
<link
  rel="stylesheet"
  href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
  integrity="sha384-GLhlTQ8iK4l5f5QlGx4FsqQDFHAnKw8x73+4N5giRTtX32w+0pLhx2r7ZmICha"
  crossorigin="anonymous"
/>

const containerStyle = {
  width: '100%',
  height: '100%',
};

const center = {
  lat: 4.8132256, // Replace with your desired center coordinates
  lng: 108.4752564,
};



const AppsalesReport = () => {
  //const [outletList, setOutletList] = useState([]);
  //const [outletName, setOutletName] = useState([]);
  
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [detailsTransactions, setDetailsTransactions] = useState([]);
  const [dailyBalanceTotalCount, setDailyBalanceTotalCount] = useState([]);
  //const [totalAmount, setTotalAmount] = useState(0.00);


  useEffect(() => {
    document.title = 'Daily balance Transaction Report';
    //getSalesData();
  }, []);

  //the outlet name need to use trim(), else there will be unintended spacing behind the outlet name
  const getTopUpCount = () => {
    var datenow = new Date();
    if (dateFrom == null || dateFrom == "" || dateTo == null || dateTo == "") {
      return null
    }
    else {
      fetch(`${API_URL}/getWeeklyTopUp`, {
        method: "POST",
        'headers': {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "dateFrom": dateFrom,
          "dateTo": dateTo
        })
      })
        .then(async (response) => {
          const jsonRes = await response.json();
          const topUpData = jsonRes.topUpData;
          let transactionsData = [];
          transactionsData.push(...topUpData)


          fetch(`${API_URL}/getWeeklyBalanceSpent`, {
            method: "POST",
            'headers': {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              "dateFrom": dateFrom,
              "dateTo": dateTo
            })
          })
            .then(async (spentResponse) => {
              const spentJson = await spentResponse.json();
              const spentData = spentJson.spentData;
              transactionsData.push(...spentData)
              const dates = topUpData
                .map((uData) => uData.createdAt.split('T')[0])
                .filter((value, index, self) => self.indexOf(value) === index);

              const topUpByWeek = [];
              dates.forEach((eachDate) => {
                topUpByWeek.push({ "topUpCount": 0, "day": eachDate, "totalAmount": 0, "spentTotal": 0 })
              });

              topUpData.forEach((data) => {
                // userByWeek[data.salesOutlet].salesAmount += data.salesAmount;
                // userByWeek[data.salesOutlet].salesCount++;
                topUpByWeek.forEach((byDate) => {
                  if (byDate.day == data.createdAt.split('T')[0]) {
                    byDate.topUpCount++;
                    byDate.totalAmount += data.amount;
                  }
                })
              });

              spentData.forEach((data) => {
                // userByWeek[data.salesOutlet].salesAmount += data.salesAmount;
                // userByWeek[data.salesOutlet].salesCount++;
                topUpByWeek.forEach((byDate) => {
                  if (byDate.day == data.createdAt.split('T')[0]) {
                    byDate.spentTotal += data.amount;
                  }
                })
              });
              topUpByWeek.sort((a, b) => new Date(a.day) - new Date(b.day));
              setDailyBalanceTotalCount(topUpByWeek)
              setDetailsTransactions(transactionsData)
            })




        })
    }
  }

  const columns = [
    { field: 'transactionID', headerName: 'Transaction ID', width: 250 },
    { field: 'paymentName', headerName: 'Customer name', width: 250 },
    { field: 'transactionType', headerName: 'Transaction Type', width: 300 },
    { field: 'description', headerName: 'Description', width: 250 },
    { field: 'amount', headerName: 'Amount', width: 70 },
    { field: 'transactionDateTime', headerName: 'Transaction Date Time', width: 200 },
  ];

  function getRowId(row) {
    return row.transactionID;
  }


  return (

    <div style={({ height: "100vh" }, { display: "flex" })}>
      <SideMenu />
      {/* Main content */}
      {/* <div className="content-wrapper"> */}
      {/* <div>
        <Title title={"Send Notification"}></Title>

      </div> */}
      <div style={({ width: '100%' }, { margin: '20px' })}>
        <h2>Daily Balance Transactions</h2>

        <form>
          <div className="form-group">
            <label>From</label>
            <input type="date" name="dateFrom" className="form-control" onChange={(e) => setDateFrom(e.target.value)} />
          </div>
          <div className="form-group">
            <label>To</label>
            <input type="date" className="form-control" name="dateTo" onChange={(e) => setDateTo(e.target.value)} />
          </div>

        </form>
        <button onClick={getTopUpCount} className="btn btn-primary">
          Query
        </button>
        <br></br>


        {/* Daily Balance Transaction over a week */}
        <div className="card" style={({ width: '100%' }, { marginTop: '20px' })}>
          <div className="card-header">
            <div className="container ">
              <div className="row">
                <div className=" text-left">
                  Daily Balance Transactions past week
                </div>

              </div>
            </div>
          </div>
          <div className="card-body">
            {dailyBalanceTotalCount.length > 0 ? (
              <div style={{ width: '100%', height: '300px' }}>
                <LineChart
                  dataset={dailyBalanceTotalCount}
                  xAxis={[
                    {
                      //id: 'barCategories',
                      dataKey: 'day',
                      //data: outletName,
                      scaleType: 'band',
                      tickLabelStyle: {
                        angle: 30,
                        textAnchor: 'start',
                        fontSize: 9,
                      },
                    },
                  ]}
                  series={[
                    { dataKey: "totalAmount", label: 'Top Up' },
                    { dataKey: "spentTotal", label: 'Spent' },
                  ]}
                  width={1300}
                  height={300}
                />
              </div>
            ) : (
              <p>No Data Available</p>
            )}
          </div>
        </div>

        <DataGrid
          rows={detailsTransactions}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          getRowId={getRowId}
          pageSizeOptions={[10, 50, 100]}
          //checkboxSelection
          //onRowSelectionModelChange={getSelectedRow} //when checkbox is checked, will pass as param to the function
          slots={{ toolbar: GridToolbar }}
          sx={{ height: 1 / 2 }}
        />


      </div>


      {/* <Footer /> */}
    </div>
  );
};

export default AppsalesReport;
