import React, { useState, useEffect } from "react";

import SideMenu from "../Components/SideMenu";
import AWS from "aws-sdk";
//import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox } from '@mui/material';
import { API_URL } from "../Constants/APIURL";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';



// Configure the AWS SDK with your credentials
AWS.config.update({
  accessKeyId: "AKIAU7JWGTGRLUIUZCH6",
  secretAccessKey: "6FuhIojnaBGmwGFiZZ7GIenaphsX0K77ZuELQKyq",
  region: "ap-southeast-1", // Replace with your desired AWS region, e.g., 'us-east-1'
});
<link
  rel="stylesheet"
  href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
  integrity="sha384-GLhlTQ8iK4l5f5QlGx4FsqQDFHAnKw8x73+4N5giRTtX32w+0pLhx2r7ZmICha"
  crossorigin="anonymous"
/>



const SendNotificaton = () => {
  const [allToken, setAllToken] = useState([]);
  const [selectedToken, setSelectedToken] = useState();
  const [messageTitle, setMessageTitle] = useState(null);
  const [messageBody, setMessageBody] = useState(null);

  useEffect(() => {
    getToken();
    document.title = 'Send Notification';
  }, []);

  const getToken = () => {

    fetch(`${API_URL}/getTokenList`, {
      method: "GET",
    })
      .then(async (response) => {
        const jsonRes = await response.json();
        if (response.status == 200) {
          console.log(jsonRes.token);
          setAllToken(jsonRes.token);
        }
      })
  }

  const fireNotification = () => {
    //if selected Token is not null
    if (selectedToken != "") {
      if (messageBody != "" && messageBody != null) {
        if (messageTitle != "" && messageTitle != null) {

          console.log(selectedToken)
          console.log(messageBody)
          console.log(messageTitle)
          fetch(`${API_URL}/sendNotification`, {
            method: "POST",
            credentials: "include",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              "messages": messageBody,
              "title": messageTitle,
              "pushTokens": selectedToken
            }),
          })
            .then(async (response) => {
              const jsonRes = await response.json();
              if (response.status == 200) {
                console.log(jsonRes);
                alert("Notification Sent")
              }
            })
        }
        else {
          alert("No Message Title")
        }
      }
      else {
        alert("No Message Body")
      }


    }
    else {
      alert("No Push Token Selected")
    }
  }

  const columns = [
    { field: 'userID', headerName: 'User ID', width: 70 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'gender', headerName: 'Gender', width: 70 },
    { field: 'email', headerName: 'Email', width: 130 },
    { field: 'phoneNum', headerName: 'Phone', width: 110 },
    { field: 'birthday', headerName: 'Birthday', width: 130 },
    { field: 'clientID', headerName: 'Client ID', width: 80 },
    { field: 'memberID', headerName: 'Member Card ID', width: 150 },
    { field: 'memberType', headerName: 'Member Type', width: 130 },
    { field: 'pushToken', headerName: 'Push Token', width: 500 },
  ];

  function getRowId(row) {
    return row.pushToken;
  }

  function getSelectedRow(row) {
    setSelectedToken(row)
  }


  return (
    
    <div style={({ height: "100vh" }, { display: "flex" })}>
      <SideMenu />
      {/* Main content */}
      {/* <div className="content-wrapper"> */}
      {/* <div>
        <Title title={"Send Notification"}></Title>

      </div> */}
      <div style={({ width: '100%' },{margin: '20px'})}>
        <h2>Send Notification</h2>
        <form>
          <div class="form-group">
            <label>Message Title</label>
            <input name="Title" className="form-control" onChange={(e) => setMessageTitle(e.target.value)} />
          </div>
          <div class="form-group">
            <label>Message Body</label>
            <textarea className="form-control" name="body" onChange={(e) => setMessageBody(e.target.value)} />
          </div>


        </form>



        <DataGrid
          rows={allToken}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          getRowId={getRowId}
          pageSizeOptions={[10, 50, 100]}
          checkboxSelection
          onRowSelectionModelChange={getSelectedRow} //when checkbox is checked, will pass as param to the function
          slots={{ toolbar: GridToolbar }}
          sx={{ height: 1 / 2 }}
        />

        <br></br>
        <button onClick={fireNotification} className="btn btn-primary">
          Send Notification
        </button>

      </div>


      {/* <Footer /> */}
    </div>
  );
};

export default SendNotificaton;
