import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Title from "../Components/Title";
import "../App.css";
import SideMenu from "../Components/SideMenu";
import AWS from "aws-sdk";
//import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox } from '@mui/material';
import { API_URL } from "../Constants/APIURL";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts/LineChart";
import {
  GoogleMap,
  LoadScript,
  HeatmapLayer,
  HeatmapLayerF,
} from "@react-google-maps/api";

// Configure the AWS SDK with your credentials
AWS.config.update({
  accessKeyId: "AKIAU7JWGTGRLUIUZCH6",
  secretAccessKey: "6FuhIojnaBGmwGFiZZ7GIenaphsX0K77ZuELQKyq",
  region: "ap-southeast-1", // Replace with your desired AWS region, e.g., 'us-east-1'
});
<link
  rel="stylesheet"
  href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
  integrity="sha384-GLhlTQ8iK4l5f5QlGx4FsqQDFHAnKw8x73+4N5giRTtX32w+0pLhx2r7ZmICha"
  crossorigin="anonymous"
/>;

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 4.8132256, // Replace with your desired center coordinates
  lng: 108.4752564,
};

const Report = () => {
  //const [outletList, setOutletList] = useState([]);
  //const [outletName, setOutletName] = useState([]);
  const [outletSalesData, setOutletSalesData] = useState([]);
  const [outletFeedback, setOutletFeedback] = useState([]);
  const [userWeekCount, setUserWeekCount] = useState([]);
  const [dailyBalanceWeekCount, setDailyBalanceWeekCount] = useState([]);

  const [map, setMap] = useState(null);
  const [heatmapData, setHeatmapData] = useState([]);

  useEffect(() => {
    document.title = "Report Dashboard";
    getSalesData();
    getWeeklyUserRate();
    //getLocationData();
    getTopUpCount();
    getRating();
  }, []);

  //the outlet name need to use trim(), else there will be unintended spacing behind the outlet name
  const getSalesData = () => {
    var datenow = new Date();
    var today = datenow.toISOString().split("T")[0];
    fetch(`${API_URL}/getSalesData`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        dateFrom: today,
        dateTo: today,
      }),
    }).then(async (response) => {
      const jsonRes = await response.json();
      if (response.status == 200) {
        var salesDataJSON = jsonRes.salesData;
        const uniqueSalesOutlets = salesDataJSON
          .map((order) => order.salesOutlet.trim())
          .filter((value, index, self) => self.indexOf(value) === index);

        //setOutletList(uniqueSalesOutlets)
        //const modifiedOutlets = uniqueSalesOutlets.map((outlet) => outlet.replace('Daily Coffee ', ''));

        //setOutletName(modifiedOutlets)

        const salesDataByOutlet = [];

        // Assign initial values to each outlet
        uniqueSalesOutlets.forEach((outlet) => {
          salesDataByOutlet.push({
            salesAmount: 0.0,
            salesCount: 0,
            outlet: outlet,
            outletName: outlet.replace("Daily Coffee ", ""),
          });
        });

        salesDataJSON.forEach((data) => {
          // salesDataByOutlet[data.salesOutlet].salesAmount += data.salesAmount;
          // salesDataByOutlet[data.salesOutlet].salesCount++;
          salesDataByOutlet.forEach((byOutlet) => {
            if (byOutlet.outlet.trim() == data.salesOutlet.trim()) {
              byOutlet.salesAmount = parseFloat(
                (byOutlet.salesAmount + data.salesAmount).toFixed(2)
              );
              byOutlet.salesCount++;
            }
          });
        });

        await setOutletSalesData(salesDataByOutlet);

        console.log(uniqueSalesOutlets);
      }
    });
  };

  const getWeeklyUserRate = () => {
    var datenow = new Date();
    var sevenDaysAgo = new Date(datenow);
    sevenDaysAgo.setDate(datenow.getDate() - 7);
    var today = datenow.toISOString().split("T")[0];
    var lastWeek = sevenDaysAgo.toISOString().split("T")[0];
    fetch(`${API_URL}/getWeeklyUser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        dateFrom: lastWeek,
        dateTo: today,
      }),
    }).then(async (response) => {
      const jsonRes = await response.json();
      const userData = jsonRes.userData;

      const dates = userData
        .map((uData) => uData.createdAt.split("T")[0])
        .filter((value, index, self) => self.indexOf(value) === index);

      const userByWeek = [];
      dates.forEach((eachDate) => {
        userByWeek.push({ userCount: 0, day: eachDate });
      });

      userData.forEach((data) => {
        // userByWeek[data.salesOutlet].salesAmount += data.salesAmount;
        // userByWeek[data.salesOutlet].salesCount++;
        userByWeek.forEach((byDate) => {
          if (byDate.day == data.createdAt.split("T")[0]) {
            byDate.userCount++;
          }
        });
      });

      setUserWeekCount(userByWeek);
    });
  };

  const getLocationData = () => {
    //  fetch(`${API_URL}/getLocationData`, {
    //    method: "GET",
    //    'headers': {
    //      'Content-Type': 'application/json'
    //    },
    //  })
    //  .then(async(response)=>{
    //    const jsonRes = await response.json();
    //    setHeatmapData(jsonRes.locationData);
    //  })

    const yourDataPoints = [
      { lat: 5.4484305, lng: 100.3079761 },
      { lat: 5.4484305, lng: 100.3079761 },
      { lat: 5.4484305, lng: 100.3079761 },
      { lat: 5.4484305, lng: 100.3079761 },
      { lat: 5.4484305, lng: 100.3079761 },
      { lat: 5.4484305, lng: 100.3079761 },
      { lat: 5.4484305, lng: 100.3079761 },
      { lat: 5.4484305, lng: 100.3079761 },
      { lat: 5.4484305, lng: 100.3079761 },
      { lat: 5.4484305, lng: 100.3079761 },
      // ... add your 100 data points ...
    ];
    setHeatmapData(yourDataPoints);
  };

  const getTopUpCount = () => {
    var datenow = new Date();
    var sevenDaysAgo = new Date(datenow);
    sevenDaysAgo.setDate(datenow.getDate() - 7);
    var today = datenow.toISOString().split("T")[0];
    var lastWeek = sevenDaysAgo.toISOString().split("T")[0];
    fetch(`${API_URL}/getWeeklyTopUp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        dateFrom: lastWeek,
        dateTo: today,
      }),
    }).then(async (response) => {
      const jsonRes = await response.json();
      const topUpData = jsonRes.topUpData;

      fetch(`${API_URL}/getWeeklyBalanceSpent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dateFrom: lastWeek,
          dateTo: today,
        }),
      }).then(async (spentResponse) => {
        const spentJson = await spentResponse.json();
        const spentData = spentJson.spentData;
        const dates = topUpData
          .map((uData) => uData.createdAt.split("T")[0])
          .filter((value, index, self) => self.indexOf(value) === index);

        const topUpByWeek = [];
        dates.forEach((eachDate) => {
          topUpByWeek.push({
            topUpCount: 0,
            day: eachDate,
            totalAmount: 0,
            spentTotal: 0,
          });
        });

        topUpData.forEach((data) => {
          // userByWeek[data.salesOutlet].salesAmount += data.salesAmount;
          // userByWeek[data.salesOutlet].salesCount++;
          topUpByWeek.forEach((byDate) => {
            if (byDate.day == data.createdAt.split("T")[0]) {
              byDate.topUpCount++;
              byDate.totalAmount += data.amount;
            }
          });
        });

        spentData.forEach((data) => {
          // userByWeek[data.salesOutlet].salesAmount += data.salesAmount;
          // userByWeek[data.salesOutlet].salesCount++;
          topUpByWeek.forEach((byDate) => {
            if (byDate.day == data.createdAt.split("T")[0]) {
              byDate.spentTotal += data.amount;
            }
          });
        });
        topUpByWeek.sort((a, b) => new Date(a.day) - new Date(b.day));
        setDailyBalanceWeekCount(topUpByWeek);
      });
    });
  };

  const onLoad = React.useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback((map) => {
    setMap(null);
  }, []);

  const getRating = () => {
    var datenow = new Date();
    var today = datenow.toISOString().split("T")[0];
    const desiredDate = new Date("2023-10-01");
    var firstDate = desiredDate.toISOString().split("T")[0];

    fetch(`${API_URL}/getFeedback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        dateFrom: firstDate,
        dateTo: today,
      }),
    }).then(async (response) => {
      const jsonRes = await response.json();
      //console.log(jsonRes.feedbacksData)
      if (response.status == 200) {
        var feedbackDataJSON = jsonRes.feedbacksData;
        const uniqueSalesOutlets = feedbackDataJSON
          .map((feedback) => feedback.outlet.trim())
          .filter((value, index, self) => self.indexOf(value) === index);

        //setOutletList(uniqueSalesOutlets)
        //const modifiedOutlets = uniqueSalesOutlets.map((outlet) => outlet.replace('Daily Coffee ', ''));

        //setOutletName(modifiedOutlets)

        const feedbackDataByOutlet = [];

        // Assign initial values to each outlet
        uniqueSalesOutlets.forEach((outlet) => {
          feedbackDataByOutlet.push({
            rating:5,
            rateCount:1,
            outlet: outlet,
            outletName: outlet.replace("Daily Coffee ", ""),
          });
        });

        feedbackDataJSON.forEach((data) => {
          // feedbackDataByOutlet[data.salesOutlet].salesAmount += data.salesAmount;
          // feedbackDataByOutlet[data.salesOutlet].salesCount++;
          feedbackDataByOutlet.forEach((byOutlet) => {
            if (byOutlet.outlet.trim() == data.outlet.trim()) {
              byOutlet.rating = parseFloat(
                (byOutlet.rating + data.rating).toFixed(2)
              );
              byOutlet.rateCount++;
            }
          });
        });

        feedbackDataByOutlet.forEach((outletRating) => {
          outletRating.rating = parseFloat((outletRating.rating/outletRating.rateCount).toFixed(2));
        })

        await setOutletFeedback(feedbackDataByOutlet);

        //console.log(feedbackDataByOutlet);
      }
    });
  };

  return (
    <div
      style={{ height: "100%", display: "flex", backgroundColor: "#f4f6f9" }}
    >
      <SideMenu />
      {/* Main content */}
      {/* <div className="content-wrapper"> */}
      {/* <div>
        <Title title={"Send Notification"}></Title>

      </div> */}
      <div className="main-container">
        <Title title={"Report Dashboard"}></Title>
        <section className="content">
          <div className="grid-container">

            {/* Today's App Sales*/}
            <div className="card">
              <div className="card-header">
                <div className="container ">
                  <div className="row">
                    <div className="col-9 text-left">Today's App Sales</div>
                    <div className="col-3 text-right">
                      <Link to="/AppsalesReport">More Details &gt;&gt;</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {outletSalesData.length > 0 ? (
                  <div style={{ width: "600px", height: "300px" }}>
                    <BarChart
                      dataset={outletSalesData}
                      xAxis={[
                        {
                          id: "barCategories",
                          dataKey: "outletName",
                          //data: outletName,
                          scaleType: "band",
                          tickLabelStyle: {
                            angle: 30,
                            textAnchor: "start",
                            fontSize: 9,
                          },
                        },
                      ]}
                      series={[
                        {
                          dataKey: "salesAmount",
                        },
                      ]}
                      width={500}
                      height={300}
                    />
                  </div>
                ) : (
                  <p>No Data Available</p>
                )}
              </div>
            </div>

            {/* Today's new user */}
            <div className="card">
              <div className="card-header">
                <div className="container ">
                  <div className="row">
                    <div className="col-9 text-left">
                      User Rate over past week
                    </div>
                    <div className="col-3 text-right">
                      More Details &gt;&gt;
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {userWeekCount.length > 0 ? (
                  <div style={{ width: "600px", height: "300px" }}>
                    <LineChart
                      dataset={userWeekCount}
                      xAxis={[
                        {
                          //id: 'barCategories',
                          dataKey: "day",
                          //data: outletName,
                          scaleType: "band",
                          tickLabelStyle: {
                            angle: 30,
                            textAnchor: "start",
                            fontSize: 9,
                          },
                        },
                      ]}
                      series={[
                        {
                          dataKey: "userCount",
                        },
                      ]}
                      width={500}
                      height={300}
                    />
                  </div>
                ) : (
                  <p>No Data Available</p>
                )}
              </div>
            </div>

            {/* Daily Balance Transaction over a week */}
            <div className="card">
              <div className="card-header">
                <div className="container ">
                  <div className="row">
                    <div className="col-9 text-left">
                      Daily Balance Transactions past week
                    </div>
                    <div className="col-3 text-right">
                      <Link to="/DailyBalanceReport">
                        More Details &gt;&gt;
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {dailyBalanceWeekCount.length > 0 ? (
                  <div style={{ width: "600px", height: "300px" }}>
                    <LineChart
                      dataset={dailyBalanceWeekCount}
                      xAxis={[
                        {
                          //id: 'barCategories',
                          dataKey: "day",
                          //data: outletName,
                          scaleType: "band",
                          tickLabelStyle: {
                            angle: 30,
                            textAnchor: "start",
                            fontSize: 9,
                          },
                        },
                      ]}
                      series={[
                        { dataKey: "totalAmount", label: "Top Up" },
                        { dataKey: "spentTotal", label: "Spent" },
                      ]}
                      width={500}
                      height={300}
                    />
                  </div>
                ) : (
                  <p>No Data Available</p>
                )}
              </div>
            </div>

             {/* Feedbacks Rating*/}
             <div className="card">
              <div className="card-header">
                <div className="container ">
                  <div className="row">
                    <div className="col-9 text-left">Store Rating</div>
                    <div className="col-3 text-right">
                      <Link to="/FeedbacksReport">More Details &gt;&gt;</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {outletFeedback.length > 0 ? (
                  <div style={{ width: "600px", height: "300px" }}>
                    <BarChart
                      dataset={outletFeedback}
                      xAxis={[
                        {
                          id: "barCategories",
                          dataKey: "outletName",
                          //data: outletName,
                          scaleType: "band",
                          tickLabelStyle: {
                            angle: 30,
                            textAnchor: "start",
                            fontSize: 9,
                          },
                        },
                      ]}
                      series={[
                        {
                          dataKey: "rating",
                        },
                      ]}
                      width={500}
                      height={300}
                    />
                  </div>
                ) : (
                  <p>No Data Available</p>
                )}
              </div>
            </div>

          </div>
        </section>
      </div>

      {/* <Footer /> */}
    </div>
  );
};

export default Report;
