import React, { useState, useEffect } from "react";
import Title from "../../Components/Title";
import AWS from "aws-sdk";
import { API_URL } from "../../Constants/APIURL";
import Alert from "../../Components/Alert";
import Table from "../../Components/Table";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../Components/SideMenu";
// Configure the AWS SDK with your credentials
AWS.config.update({
  accessKeyId: "AKIAU7JWGTGRLUIUZCH6",
  secretAccessKey: "6FuhIojnaBGmwGFiZZ7GIenaphsX0K77ZuELQKyq",
  region: "ap-southeast-1", // Replace with your desired AWS region, e.g., 'us-east-1'
});

const ManageUser = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [usersData, setUsersData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = () => {
    fetch(`${API_URL}/getAllUsers`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          setIsError(true);
          setMessage(`HTTP error: ${response.status}`);
        }
        return response.json();
      })
      .then((usersData) => {
        setUsersData(usersData);
        console.log(usersData);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching users", error);
      });
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.userID,
      sortable: true,
      width: "80px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "200px",
    },
    {
      name: "Phone",
      selector: (row) => row.phoneNum,
      sortable: true,
      width: "200px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "300px",
    },
    {
      name: "Member Type",
      selector: (row) => {
        if (row.members && row.members.length > 0) {
          return row.members[0].memberType;
        }
        return "N/A"; // Or some other default value for empty member types
      },
      sortable: true,
      width: "250px",
    },
    {
      name: "Date of Birth",
      selector: (row) => {
        const birthdayDate = new Date(row.birthday);
        return birthdayDate.toLocaleDateString();
      },
      sortable: true,
      width: "200px",
    },
  ];

  if (loading) {
    return (
      <div className="content-wrapper">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div
      style={{ height: "100%", display: "flex", backgroundColor: "#f4f6f9" }}
    >
      <SideMenu />
      {/* Main content */}
      <div className="main-container">
        <Title title={"Manage User"}></Title>
        {message ? <Alert isError={isError} message={message} /> : null}
        <section className="content">
          <div className="container-fluid">
            <Table
              columns={columns}
              data={usersData}
              searchableAttribute={["email", "phoneNum", "name"]}
            ></Table>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ManageUser;
