import React, { useState, useEffect } from "react";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import Title from "../../Components/Title";
import { API_URL } from "../../Constants/APIURL";
import Table from "../../Components/Table";
import Alert from "../../Components/Alert";
import AWS from "aws-sdk";
import SideMenu from "../../Components/SideMenu";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { IoIosMore } from "react-icons/io";
import { useLocation } from "react-router-dom";
import { event } from "jquery";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

AWS.config.update({
  accessKeyId: "AKIAU7JWGTGRLUIUZCH6",
  secretAccessKey: "6FuhIojnaBGmwGFiZZ7GIenaphsX0K77ZuELQKyq",
  region: "ap-southeast-1", // Replace with your desired AWS region, e.g., 'us-east-1'
});

const ManageFAQ = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [faqContent, setFaqContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(null);

  //add category
  const [openAdd, setOpenAdd] = useState(false);
  const [addCategory, setAddCategory] = useState(null);

  //edit category
  const [menuCategory, setMenuCategory] = useState(null);
  const [editedCategory, setEditedCategory] = useState(null);
  const [editCategoryName, setEditCategoryName] = useState(null);
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const [anchorE12, setAnchorE12] = React.useState(null);
  const openAddMenu = Boolean(anchorE12);

  useEffect(() => {
    console.log("detected");
    console.log(location);
    // Check if there is state passed from the previous page
    if (location.state) {
      console.log(location.state);
      setIsError(location.state.isError);
      setMessage(location.state.message);
    }
  }, [location.state]);

  useEffect(() => {
    loadFaq();
  }, []);

  //Faq category menu
  const handleClick = (event, categoryID) => {
    setAnchorEl(event.currentTarget);
    setMenuCategory(categoryID);
  };
  const handleMenuClose = (categoryID) => {
    setAnchorEl(null);
    setMenuCategory(categoryID);
  };

  //add new menu
  const handleAddClick = (event) => {
    setAnchorE12(event.currentTarget);
  };

  const handleAddClose = () => {
    setAnchorE12(null);
  };

  const handleAddCategoryModal = () => {
    setOpenAdd(true);
  };

  const handleCloseAddCategory = () => {
    setOpenAdd(false);
  };

  const handleAddCategory = (e) => {
    e.preventDefault();
    if (addCategory) {
      const payload = {
        categoryName: addCategory,
      };
      fetch(`${API_URL}/addFaqCategory`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          if (!response.ok) {
            setIsError(true);
            setMessage("HTTP Upload Error");
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          setIsError(false);
          setMessage("Faq Category added successfully");
        })
        .catch((error) => {
          setIsError(true);
          setMessage("Upload Error");
          console.error("Upload Error:", error);
        });
    }
    setOpenAdd(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const editCategoryModal = (categoryID) => {
    const selectedCategory = faqContent.find(
      (item) => item.categoryID === categoryID
    );
    setEditedCategory(selectedCategory);
    setEditCategoryName(selectedCategory.categoryName);
    setOpen(true);
  };

  const handleDeleteCategory = (categoryID, categoryName) => {
    const selectedCategory = faqContent.find(
      (item) => item.categoryID === categoryID
    );
    if (selectedCategory.content.length === 0) {
      const confirmDelete = window.confirm(
        "Are you sure you want to delete " + categoryName + "?"
      );
      if (confirmDelete) {
        fetch(`${API_URL}/deleteFaqCategory?faqCategoriesID=${categoryID}`, {
          method: "DELETE",
        })
          .then((response) => {
            if (!response.ok) {
              // throw new Error(`HTTP error: ${response.status}`);
              setIsError(true);
              setMessage(`HTTP error: ${response.status}`);
            }
            //update the state after successful deletion
            const updatedFAQData = faqContent.filter(
              (item) => item.categoryID !== categoryID
            );
            setFaqContent(updatedFAQData);

            setIsError(false);
            setMessage(`FAQ Category deleted successfully`);
          })
          .catch((error) => {
            console.log("Error deleting FAQ Category", error);
          });
      }
    } else {
      window.alert(
        "You cannot delete FAQ categories that have FAQ content inside "
      );
    }
  };

  const saveCategoryEdit = () => {
    const payload = {
      faqCategories: editCategoryName,
    };

    fetch(`${API_URL}/updateFaqCategory?id=${editedCategory.categoryID}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          setIsError(true);
          setMessage("HTTP Update Error");
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        setIsError(false);
        setMessage("File updated successfully");
        console.log("File updated successfully.");

        loadFaq();
        setOpen(false);
      })
      .catch((error) => {
        setIsError(true);
        setMessage("Update Error");
        console.error("Update error:", error);
      });
  };

  const loadFaq = () => {
    fetch(`${API_URL}/getAllContent`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          setIsError(true);
          setMessage(`HTTP error: ${response.status}`);
        }
        return response.json();
      })
      .then((faqData) => {
        setFaqContent(Array.isArray(faqData.data) ? faqData.data : []); // Ensure faqContent is an array
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching faq data", error);
        setLoading(false);
      });
  };

  const handleQuestionClick = (categoryID) => {
    navigate(`/editFAQ/${categoryID}`);
  };

  if (loading) {
    return (
      <div className="content-wrapper">
        <p>Loading...</p>
      </div>
    );
  }

  const handleDetailsClick = () => {};
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        backgroundColor: "#f4f6f9",
      }}
    >
      <SideMenu />
      {/* Main Content */}
      <div className="main-container">
        <Title title={"Manage FAQ"}></Title>
        {message ? <Alert isError={isError} message={message} /> : null}
        <section className="content">
          <div className="container-fluid">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={(event) => handleAddClick(event)}
                variant="contained"
                style={{ marginBottom: "10px" }}
              >
                Add New
              </Button>
              <Menu
                anchorEl={anchorE12}
                keepMounted
                onClose={handleAddClose}
                open={openAddMenu}
              >
                <MenuItem onClick={handleAddCategoryModal}>Category</MenuItem>
                <MenuItem onClick={() => navigate("/AddFAQ")}>
                  FAQ Content
                </MenuItem>
              </Menu>
            </div>

            {faqContent.map((faqItem, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid container alignItems="center">
                    <Typography style={{ marginRight: "8px" }}>
                      {faqItem.categoryName}
                    </Typography>
                    {/* <Button
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      editCategoryModal(faqItem.categoryID);
                    }}
                  >
                    Edit Category
                  </Button> */}
                  </Grid>

                  <IconButton
                    onClick={(event) => handleClick(event, faqItem.categoryID)}
                    color="primary"
                  >
                    <IoIosMore />
                  </IconButton>

                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    onClose={handleMenuClose}
                    open={openMenu}
                  >
                    <MenuItem
                      onClick={() => {
                        editCategoryModal(menuCategory);
                        handleMenuClose(faqItem.categoryID);
                      }}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        handleDeleteCategory(
                          faqItem.categoryID,
                          faqItem.categoryName
                        );
                      }}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </AccordionSummary>
                {faqItem.content.map((content) => (
                  <AccordionSummary
                    onClick={() => handleQuestionClick(content.faqContentID)}
                  >
                    <Typography>{content.question}</Typography>
                  </AccordionSummary>
                ))}
                {/* <AccordionSummary>
                  <Typography>Add FAQ</Typography>
                </AccordionSummary> */}
              </Accordion>
            ))}
          </div>
        </section>
      </div>
      <React.Fragment>
        <Modal
          open={openAdd}
          onClose={handleCloseAddCategory}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style }}>
            <h2 id="child-modal-title">Add Category</h2>
            <div class="form-group">
              <label htmlFor="title">Category Name</label>
              <input
                onChange={(e) => setAddCategory(e.target.value)}
                type="text"
                class="form-control"
                id="title"
                value={addCategory}
              />
            </div>
            <Button onClick={handleAddCategory}>Add </Button>
          </Box>
        </Modal>
      </React.Fragment>

      <React.Fragment>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style }}>
            <h2 id="child-modal-title">Edit Category</h2>
            <div class="form-group">
              <label htmlFor="title">Category Name</label>
              <input
                onChange={(e) => setEditCategoryName(e.target.value)}
                type="text"
                class="form-control"
                id="title"
                value={editCategoryName}
              />
            </div>
            <Button onClick={saveCategoryEdit}>Save </Button>
          </Box>
        </Modal>
      </React.Fragment>
    </div>
  );
};

export default ManageFAQ;
