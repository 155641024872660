import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

const Table = (props) => {
  const { data, columns, searchableAttribute } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  //search value of table
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setFilteredData(
      data.filter((row) =>
        searchableAttribute.some((attr) =>
          row[attr].toLowerCase().includes(e.target.value.toLowerCase())
        )
      )
    );
  };
  return (
    <>
      <input
        className="mb-3"
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={handleSearch}
      />
      <DataTable columns={columns} data={filteredData} pagination search />
    </>
  );
};
export default Table;
