import React, { useEffect, useState } from "react";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../Components/SideMenu";
import Footer from "../../Components/Footer";
import Title from "../../Components/Title";
import { API_URL } from "../../Constants/APIURL";
import Alert from "../../Components/Alert";
import { useParams } from "react-router-dom";
import AWS from "aws-sdk";
import { Editor } from "@tinymce/tinymce-react";
import Button from "@mui/material/Button";
// Configure the AWS SDK with your credentials
AWS.config.update({
  accessKeyId: "AKIAU7JWGTGRLUIUZCH6",
  secretAccessKey: "6FuhIojnaBGmwGFiZZ7GIenaphsX0K77ZuELQKyq",
  region: "ap-southeast-1", // Replace with your desired AWS region, e.g., 'us-east-1'
});

const EditFAQ = () => {
  const navigate = useNavigate();
  const { FaqID } = useParams();
  const [data, setData] = useState("");
  const [allCategories, setAllCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(null);
  const editorConfig = {
    height: 500,
    menubar: false,
    plugins: [
      "advlist autolink lists link image charmap print preview anchor",
      "searchreplace visualblocks code fullscreen",
      "insertdatetime media table paste code help wordcount",
    ],
    toolbar:
      "undo redo | formatselect | " +
      "bold italic backcolor | alignleft aligncenter " +
      "alignright alignjustify | bullist numlist outdent indent | " +
      "removeformat | help",
  };

  useEffect(() => {
    loadFAQ();
    getFaqCategories();
  }, []);

  const loadFAQ = () => {
    fetch(`${API_URL}/getFaqContentById?id=${FaqID}`, {
      method: "GET",
    }).then(async (response) => {
      const jsonRes = await response.json();
      const faqContent = jsonRes.data;
      setData(faqContent);
      setCategory(faqContent.faqCategoriesID);
      setQuestion(faqContent.question);
      setAnswer(faqContent.answer);
    });
  };

  const getFaqCategories = () => {
    fetch(`${API_URL}/getAllFaqCategories`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          setIsError(true);
          setMessage(`HTTP error: ${response.status}`);
        }
        return response.json();
      })
      .then((faqCategories) => {
        console.log(faqCategories.data);
        setAllCategories(faqCategories.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching category data", error);
        setLoading(false);
      });
  };

  const handleEditorChange = (content, editor) => {
    setAnswer(content);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    const payload = {
      faqCategories: category,
      question: question,
      answer: answer,
    };

    fetch(`${API_URL}/updateFaqContent?id=${FaqID}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          setIsError(true);
          setMessage("HTTP Update Error");
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        setIsError(false);
        setMessage("File updated successfully");
        console.log("File updated successfully.");
      })
      .catch((error) => {
        setIsError(true);
        setMessage("Update Error");
        console.error("Update error:", error);
      });
  };

  const handleDeleteFaq = () => {
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (confirmDelete) {
      fetch(`${API_URL}/deleteFaqContent?id=${FaqID}`, {
        method: "DELETE",
      }).then((response) => {
        if (!response.ok) {
          // throw new Error(`HTTP error: ${response.status}`);
          response.json().then((data) => {
            setIsError(true);
            setMessage(data.message || `Error: ${response.status}`);
            navigate("/manageFAQ", { state: { isError, message } });
          });
        } else {
          // If the response is ok, parse the JSON and handle the message
          response.json().then((data) => {
            setIsError(false);
            setMessage(data.message);
            navigate("/manageFAQ", { state: { isError, message } });
          });
        }
      });
    }
  };

  return (
    <>
      <div
        style={{
          height: "100%",
          display: "flex",
          backgroundColor: "#f4f6f9",
        }}
      >
        <SideMenu />
        {/* Main content */}
        <div className="main-container">
          <Title title={"Edit FAQ Content"}></Title>
          {message ? <Alert isError={isError} message={message} /> : null}
          <section className="content">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                type="button"
                class="btn btn-danger mb-2"
                onClick={handleDeleteFaq}
              >
                Delete FAQ
              </button>
            </div>
            {/* <!-- general form elements --> */}
            <div class="card card-primary">
              {/* <!-- form start --> */}
              <form>
                <div class="card-body">
                  <div class="form-group">
                    <label htmlFor="category">Category</label>
                    <select
                      required
                      className="custom-select"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option disabled value="label">
                        Select Category
                      </option>
                      {allCategories.map((catOption) => (
                        <option
                          key={catOption.faqCategoriesID}
                          value={catOption.faqCategoriesID}
                        >
                          {catOption.faqCategories}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div class="form-group">
                    <label htmlFor="question">Question</label>
                    <input
                      onChange={(e) => setQuestion(e.target.value)}
                      type="text"
                      class="form-control"
                      id="question"
                      value={question}
                    />
                  </div>
                  <div class="form-group">
                    <label for="answer">Answer</label>
                    <Editor
                      apiKey="rt3s0p97spjqnkw6j20glwmr3j0tm5sqcdn7fr0t8fd5tejf"
                      initialValue={answer}
                      value={answer}
                      init={editorConfig}
                      onEditorChange={handleEditorChange}
                    />
                  </div>
                </div>
                {/* <!-- /.card-body --> */}

                <div class="card-footer">
                  <button onClick={onSubmitHandler} class="btn btn-primary">
                    Submit
                  </button>
                </div>
                {/* <span style={{ color: isError ? "red" : "green" }}>
              {message ? getMessage() : null}
            </span> */}
              </form>
            </div>
            {/* <!-- /.card --> */}

            {/* <!-- general form elements --> */}
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EditFAQ;
