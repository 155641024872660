import React, { useState, useEffect } from "react";

import AWS from "aws-sdk";
//import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox } from '@mui/material';
import { GoogleMap, useJsApiLoader, HeatmapLayer } from '@react-google-maps/api';

import { API_URL } from "../Constants/APIURL";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';


const containerStyle = {
  width: '100%',
  height: '500px'
};

const center = {
  lat: 5.7063688,
  lng: 107.7097349
};


// Configure the AWS SDK with your credentials
AWS.config.update({
  accessKeyId: "AKIAU7JWGTGRLUIUZCH6",
  secretAccessKey: "6FuhIojnaBGmwGFiZZ7GIenaphsX0K77ZuELQKyq",
  region: "ap-southeast-1", // Replace with your desired AWS region, e.g., 'us-east-1'
});
<link
  rel="stylesheet"
  href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
  integrity="sha384-GLhlTQ8iK4l5f5QlGx4FsqQDFHAnKw8x73+4N5giRTtX32w+0pLhx2r7ZmICha"
  crossorigin="anonymous"
/>



const Heatmap = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAJMwZBDc8F_tS1-B7BPaBw8uADyERnSwI"
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

   const heatmapData = null
  //   [
  //     {37.765153, -122.418618},
  //     {37.765136, -122.419112},
  //     {37.765136, -122.419112},
  //     {37.765129, -122.419378},
  //     {37.765119, -122.419481},
  //     {37.7651, -122.419852}
  //   ]

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={6}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      { /* Child components, such as markers, info windows, etc. */}
      <HeatmapLayer
        data={heatmapData}
        options={{
          radius: 20,
          opacity: 0.8,
        }}
      />
      <></>
    </GoogleMap>
  ) : <></>
}

export default Heatmap;
