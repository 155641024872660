import React, { useState, useEffect } from "react";
import "../../App.css";

import Footer from "../../Components/Footer";
import Title from "../../Components/Title";
import { API_URL } from "../../Constants/APIURL";
import Table from "../../Components/Table";
import { useNavigate } from "react-router-dom";
import Alert from "../../Components/Alert";
import AWS from "aws-sdk";
import SideMenu from "../../Components/SideMenu";

// Configure the AWS SDK with your credentials
AWS.config.update({
  accessKeyId: "AKIAU7JWGTGRLUIUZCH6",
  secretAccessKey: "6FuhIojnaBGmwGFiZZ7GIenaphsX0K77ZuELQKyq",
  region: "ap-southeast-1", // Replace with your desired AWS region, e.g., 'us-east-1'
});

const ManageNews = () => {
  const navigate = useNavigate();
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    loadNews();
  }, []);

  //load news database
  const loadNews = () => {
    fetch(`${API_URL}/news`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          // throw new Error(`HTTP error: ${response.status}`);
          setIsError(true);
          setMessage(`HTTP error: ${response.status}`);
        }
        return response.json();
      })
      .then((newsData) => {
        setNewsData(newsData);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching news", error);
        setLoading(false);
      });
  };

  const handleDeleteNews = async (id, newsImage) => {
    const confirmDelete = window.confirm("Are you sure you want to delete?");

    if (confirmDelete) {
      try {
        const response = await fetch(`${API_URL}/deleteNews?id=${id}`, {
          method: "DELETE",
        });

        if (!response.ok) {
          setIsError(true);
          setMessage(`HTTP error: ${response.status}`);
          return;
        }

        // Update the state after successful deletion
        const updatedNewsData = newsData.filter((item) => item.id !== id);
        setNewsData(updatedNewsData);

        // Delete image from S3
        deleteImageFromS3(newsImage);

        setIsError(false);
        setMessage(`News deleted successfully`);
      } catch (error) {
        console.log("Error deleting news", error);
      }
    }
  };

  const deleteImageFromS3 = (imageKey) => {
    //get the image key by removing the prefix url
    const key = imageKey.replace(
      "https://dcapp-bucket.s3.ap-southeast-1.amazonaws.com/",
      ""
    );
    const s3 = new AWS.S3();
    const params = {
      Bucket: "dcapp-bucket",
      Key: key, // The key of the object you want to delete
    };
    s3.deleteObject(params, (err, data) => {
      if (err) {
        console.error("Error deleting object:", err);
      } else {
        console.log("Object deleted successfully from s3:", data);
      }
    });
  };

  const handleEditNews = (newsID) => {
    navigate(`/editNews/${newsID}`);
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.newsID,
      sortable: true,
      width: "80px",
    },
    {
      name: "Title",
      selector: (row) => row.newsTitle,
      sortable: true,
      width: "400px",
      wrap: true,
    },
    // {
    //   name: "News Content",
    //   selector: (row) => row.newsContent,
    //   sortable: true,
    //   width: "400px",
    //   wrap: true,
    //   style: {},
    // },
    {
      name: "News Image",
      cell: (row) => (
        <img src={row.newsImage} style={{ width: "150px", height: "100px" }} />
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            style={{ marginRight: "10px" }}
            onClick={(e) => handleEditNews(row.newsID)}
          >
            Edit
          </button>
          <button onClick={(e) => handleDeleteNews(row.newsID, row.newsImage)}>
            Delete
          </button>
        </>
      ),
    },
  ];

  if (loading) {
    return (
      <div className="content-wrapper">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        backgroundColor: "#f4f6f9",
      }}
    >
      {/* Render only when data is available */}
      <SideMenu />
      {/* Main content */}
      <div className="main-container">
        <Title title={"Manage News"}></Title>
        {message ? <Alert isError={isError} message={message} /> : null}
        <section className="content">
          <div className="container-fluid">
            <Table
              columns={columns}
              data={newsData}
              searchableAttribute={["newsTitle"]}
            ></Table>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ManageNews;
